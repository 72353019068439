@import 'required/main';

/* Native styles */
dialog::backdrop,
/* Polyfill styles */
dialog + .backdrop {		
	background-color: rgba(0,0,0,.6);	
}			
	
dialog {
	box-sizing: border-box;
	border: none;
	box-shadow: 0 0 1em rgba(0,0,0,.25);	
	opacity: 1;
	padding: 0;
	transition: opacity 1s ease-in-out 1s;	
	color: $base-color;
	width: 90%;
	max-width: 360px;
}
	
/* test */	
dialog[open] {
	-webkit-animation: slide-up .4s ease-out;
	        animation: slide-up .4s ease-out;
}
	
	
@-webkit-keyframes slide-up {
  0% {
	opacity: 0;
	-webkit-transform: translate(0, 15px);
			transform: translate(0, 15px);
  }
  100% {
	opacity: 1;
	-webkit-transform: translate(0, 0);
			transform: translate(0, 0);
  }
}

@keyframes slide-up {
 0% {
	opacity: 0;
	-webkit-transform: translate(0, 15px);
			transform: translate(0, 15px);
  }
  100% {
	opacity: 1;
	-webkit-transform: translate(0, 0);
			transform: translate(0, 0);
  }
}
