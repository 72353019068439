@mixin transition($args...){
	-webkit-transition: $args;  
       -moz-transition: $args;
        -ms-transition: $args;
            transition: $args;	
}


@mixin transition-delay($delay...){
	-webkit-transition-delay: $delay;  
       -moz-transition-delay: $delay;
            transition-delay: $delay;	
}


@mixin transition-property($property...){
	-webkit-transition-property: $property;  
       -moz-transition-property: $property;
            transition-property: $property;	
}


@mixin transition-duration($duration...){
	-webkit-transition-duration: $duration;  
       -moz-transition-duration: $duration;
            transition-duration: $duration;	
}


@mixin transition-timing-function($timing...){
	-webkit-transition-timing-function: $timing;  
       -moz-transition-timing-function: $timing;
            transition-timing-function: $timing;	
}


@mixin transform($args) {
	-webkit-transform: $args;
       -moz-transform: $args;
        -ms-transform: $args;
            transform: $args;
}


@mixin translateY($args){
	-webkit-transform: translateY($args);
       -moz-transform: translateY($args);
        -ms-transform: translateY($args);
         -o-transform: translateY($args);
            transform: translateY($args);
}

@mixin rotate($args...){
    -webkit-transform: rotate($args);
       -moz-transform: rotate($args);
        -ms-transform: rotate($args);
         -o-transform: rotate($args);
            transform: rotate($args);
}


@mixin transform-style($val) {
	-webkit-transform-style: $val;
       -moz-transform-style: $val;
        -ms-transform-style: $val;
            transform-style: $val;
}


@mixin perspective($val) {
	-webkit-perspective: $val; 
       -moz-perspective: $val; 
        -ms-perspective: $val; 
            perspective: $val;
}


@mixin backface-visibility ($args) {
	-webkit-backface-visibility: $args;
       -moz-backface-visibility: $args;
        -ms-backface-visibility: $args;
         -o-backface-visibility: $args;
            backface-visibility: $args;
}


@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false){
	@if $inset {
		-webkit-box-shadow: inset $top $left $blur $spread $color;
           -moz-box-shadow: inset $top $left $blur $spread $color;
                box-shadow: inset $top $left $blur $spread $color;
	} @else {
		-webkit-box-shadow: $top $left $blur $color;
           -moz-box-shadow: $top $left $blur $color;
                box-shadow: $top $left $blur $color;
	}
}


@mixin word-wrap() {
	overflow-wrap: break-word;
	    word-wrap: break-word;
		
	/*		
	-ms-word-break: break-all;
   -moz-word-break: break-all;	
        word-break: break-all;	
	*/
	
	/* non standard for webkit */		
        word-break: break-word;
		
	-webkit-hyphens: auto;
       -moz-hyphens: auto;
        -ms-hyphens: auto;	
            hyphens: auto;
}


@mixin hyphens() {
	-webkit-hyphens: auto;
	   -moz-hyphens: auto;
		-ms-hyphens: auto;
			hyphens: auto;
}

/* Animation - see header.scss for usage examples */

@mixin animation($str) {
	-webkit-animation: #{$str};
       -moz-animation: #{$str};
        -ms-animation: #{$str};
         -o-animation: #{$str};
            animation: #{$str};      
}


@mixin animate($args...){
    -webkit-animation: $args;
       -moz-animation: $args;
        -ms-animation: $args;
         -o-animation: $args;
            animation: $args;
}


@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}


@mixin columns($count, $gap){
	-webkit-column-count: $count;
       -moz-column-count: $count;
            column-count: $count;
			
      -webkit-column-gap: $gap;       
         -moz-column-gap: $gap;           
              column-gap: $gap;	
}


/* Form placeholders */
@mixin placeholder { 
	::-webkit-input-placeholder { @content }
		 :-ms-input-placeholder { @content }
			  :-moz-placeholder { @content } 
			 ::-moz-placeholder { @content }
}



/* Flexbox 
***************************************/
@mixin flexbox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}


@mixin flex($values) {
     -webkit-box-flex: $values;
        -moz-box-flex: $values;
             -ms-flex: $values;
         -webkit-flex: $values;
                 flex: $values;
}


@mixin flex-wrap($value: nowrap) {
	// No Webkit Box fallback.
	-webkit-flex-wrap: $value;
	-moz-flex-wrap: $value;
	@if $value == nowrap {
		-ms-flex-wrap: none;
	} @else { 
		-ms-flex-wrap: $value; 
	}
	flex-wrap: $value;
}


@mixin flex-direction($value: row) {
	@if $value == row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal;
	} @else if $value == column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
	} @else if $value == column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical;
	} @else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal;
	}
	
	-webkit-flex-direction: $value;
       -moz-flex-direction: $value;
        -ms-flex-direction: $value;
            flex-direction: $value;
}


@mixin align-items($align) {
  -webkit-align-items: $align;
     -moz-align-items: $align;
      -ms-align-items: $align;
          align-items: $align;
}


@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
     -moz-justify-content: $justify;
      -ms-justify-content: $justify;
          justify-content: $justify;
            -ms-flex-pack: $justify;
}


@mixin appearance($value){
	-webkit-appearance: $value;
       -moz-appearance: $value;
            appearance: $value;
}


@mixin clip-path($clip){
	-webkit-clip-path: $clip;
            clip-path: $clip;
}



@function top-shadow($depth) {
  $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
  $blur: nth(1.5 3 10 14 19, $depth) * 4px;
  $color: rgba(black, nth(.12 .16 .19 .25 .30, $depth));

  @return 0 $primary-offset $blur $color;
}

@function bottom-shadow($depth) {
  $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
  $blur: nth(1 3 3 5 6, $depth) * 4px;
  $color: rgba(black, nth(.24 .23 .23 .22 .22, $depth));

  @return 0 $primary-offset $blur $color;
}


/* Card */
@mixin card($depth) {
  @if $depth < 1 {
    box-shadow: none;
  } @else if $depth > 5 {
    @warn "Invalid $depth `#{$depth}` for mixin `card`.";
  } @else {
    box-shadow: bottom-shadow($depth), top-shadow($depth);  
  }
}


/* Filter */
@mixin filter($filter-type,$filter-amount) {
	-webkit-filter: unquote($filter-type+unquote('(#{$filter-amount})'));
       -moz-filter: unquote($filter-type+unquote('(#{$filter-amount})'));
        -ms-filter: unquote($filter-type+unquote('(#{$filter-amount})'));
         -o-filter: unquote($filter-type+unquote('(#{$filter-amount})'));
            filter: unquote($filter-type+unquote('(#{$filter-amount})'));
}


/* Gridder */
@mixin rowMachine($numPerRow, $margin) {
  width: ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);
  &:nth-child(n) {
    margin-bottom: $margin;
    margin-right: $margin;
  }
  &:nth-of-type(#{$numPerRow}n) {
    margin-right: 0;
    margin-bottom: 0;
  }
}




// Add SVG Mask
@mixin mask($url) {
	-webkit-mask: url($url) no-repeat 50% 50%; 
            mask: url($url) no-repeat 50% 50%; 	
	//-webkit-mask-size: 100%;			
            //mask-size: 100%; 	
}


///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback), 
///                 minumum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;
  
  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }
  
  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;
    
    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }
  
  @if $fallback {
    font-size: $fallback;
  }
  
  font-size: $responsive;
}