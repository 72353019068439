@import 'required/main';

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{margin:0;padding:0;border:0;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}table{border-collapse:collapse;border-spacing:0}


/* Layout shit! */
html, body { height: 100%; }

main { display: block; }


body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  box-sizing: border-box;
  background-color: #000;
  color: #ccc;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  min-height: 320px; 
}

h1, h2, h3, h4, h5, h6 { line-height: 1.2em; }

h1 { }
h2 { font-size: 1.5em; font-weight: 700; margin-bottom: .5em; }

ul { 
	list-style-type: disc; 
	margin: 0 0 1.4em;
	line-height: 1.4em;
}

p {
	margin: 0 0 1.4em;
	line-height: 1.4em;
}

a { color: currentColor; }

button {
	border: none;
	background-color: transparent;
	cursor: pointer;
}

.unstyled-list {
	list-style-type: none;
}

.object-fit {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.main-content { text-align: center; }

.site-logo {
	@include transition(opacity 2s, letter-spacing 2s);	
	font-size: 6vw;
	font-weight: 900;
	margin-bottom: .4em;
	text-transform: uppercase;	
	opacity: 0;		
	letter-spacing: .05em;
	white-space: nowrap;
	
	&.visible { 		
		opacity: 1; 
		letter-spacing: normal;
	}
	
	> span {
		@include transition(color 1s, transform 1s);
		display: inline-block;
		vertical-align: top;		
	}
	
	@media #{$tablet} {
		font-size: 9vw;
	}
	
	@media #{$mobile} {
		font-size: 10vw;
	}	
	
}

.sl-2 { 
	&.active {		
		@include transform(skew(-12deg));
		color: #ccc;					
	}
}


.icon-list { 
	@include flexbox;
	margin-bottom: 3em;
	
	> li { 
		@include transition(opacity 1s ease-in, transform 1s);
		@include transform(translate3d(0,20px,0));
		opacity: 0;	
		width: calc(100%/6);
		pointer-events: none;
	}	
	
	&.visible {
		> li { 
			@include transform(translate3d(0,0,0));
			opacity: 1;
			pointer-events: auto;
			
			&:nth-child(2) { @include transition-delay(.15s); }
			&:nth-child(3) { @include transition-delay(.3s); }
			&:nth-child(4) { @include transition-delay(.45s); }
			&:nth-child(5) { @include transition-delay(.6s); }
			&:nth-child(6) { @include transition-delay(.75s); }
			&:nth-child(7) { @include transition-delay(.9s); }

		}				
	}
}

.no-js .icon-list > li { pointer-events: auto; }


.icon { 
	@include transition(background-color .5s ease-in, color .5s);
	display: inline-block;
	font-size: 3.75vw; 
	line-height: 1.4em;
	border-radius: 50%;
	padding: .1em;
	
	&:hover { 
		background-color: $brand-color; 
		color: #fff; 
	}
	
	@media #{$tablet} {
		font-size: 6vw;
	}
	
	@media #{$mobile} {
		font-size: 7.5vw;
	}	
}


.card {
	color: $base-color;	
	font-size: 14px;
	
	figcaption {
		padding: 1.5em 2em;
	}
}

.card-img { 		
	height: 180px;
	overflow: hidden;
	
	img { object-position: 50% 20%; }
}

.btn-close {
	@include transition(background-color .5s);
	border: none;
	display: block;
	width: 32px;
	height: 32px;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	background-color: rgba(0,0,0,.6);
	
	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 16px;
		width: 32px;
		height: 1px;
		background-color: #fff;		
	}
	
	&:before { @include transform(rotate(45deg)); left: 0; }
	&:after { @include transform(rotate(-45deg)); right: 0; }
	
	&:hover { background-color: #000; }
}


.btn { 
	@include transition(opacity 1s ease-in, transform 1s, background-color .5s);
	@include transform(translate3d(0,20px,0));
	display: inline-block;
	font-family: $secondary-font;
	font-weight: 700;
	letter-spacing: .05em;
	text-transform: uppercase;
	padding: 1em 1.5em 1em 1.55em; 
	background-color: $brand-color; 
	color: #fff; 
	opacity: 0;
	pointer-events: none;	
	border-radius: 2em;
	
	&.visible {
		@include transform(translate3d(0,0,0));
		opacity: 1;
		pointer-events: auto;	
	}
	
	&:hover {
		background-color: #666666;
	}
}

.hero {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-filter: blur(0px);
  filter: blur(0px);
  transition: -webkit-filter .5s;
  transition: filter .5s; }
  .is-loaded .hero {
    -webkit-filter: blur(5px);
    filter: blur(5px); }
  @media screen and (max-width: 800px) {
    .hero .object-fit {
      object-position: 20% 20%; } }
  @media screen and (max-width: 767px) {
    .hero .object-fit {
      object-position: 50% 50%; } }
.no-js .btn { pointer-events: auto; }