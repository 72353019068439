/* Variables
**********************************/
/* all vars, no need for @if critical */
$base-font: 'Roboto', sans-serif !default;
$secondary-font: 'Lato, sans-serif !default';

$base-color: #666666 !default;
$brand-color: #495966 !default;
$brand-color-light: #4d617d;
$secondary-color: #cdcdcd !default;


/* breakpoints */
$break-large: 'screen and (max-width: 1000px)' !default;
$tablet: 'screen and (max-width: 768px)' !default;
$mobile: 'screen and (max-width: 670px)' !default;
$small-mobile: 'screen and (max-width: 480px)' !default;